import { useEffect } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDispatch, useSelector } from "react-redux";
import { getBalances, selectRebaeBalance, selectLastRebaseTime, selectTotalSupply, selectCirculatingSupply, selectMaxWallet, selectMaxSell, selectLastBlockTimestamp } from "../redux/slices/web3Slice";
import useInterval from "../util/useInterval";
import styles from "../App.module.css";
import toFormattedNumber from "../util/toFormattedNumber";
import moment from 'moment/moment'

function Dashboard() {
    const rebaeBalance = '' + useSelector(selectRebaeBalance);
    const lastBlockTimestamp = '' + useSelector(selectLastBlockTimestamp);
    const lastRebaseTime = '' + useSelector(selectLastRebaseTime);
    const totalSupply = '' + useSelector(selectTotalSupply);
    const circulatingSupply = '' + useSelector(selectCirculatingSupply);
    const maxWallet = '' + useSelector(selectMaxWallet);
    const maxSell = '' + useSelector(selectMaxSell);
    const dispatch = useDispatch();
  
    const timeNow = moment.unix(lastBlockTimestamp).utc().subtract(1, 'hour');
    const nextRebaseDate = moment.unix(lastRebaseTime).utc().add(15, 'm');
    const timeUntilNextRebase = moment.duration(nextRebaseDate.diff(timeNow));
    let nextRebaseString = <h2>Pending...</h2>;
    if (true) {//timeNow < nextRebaseDate) {
        let minutes = timeUntilNextRebase.minutes();
        let seconds = timeUntilNextRebase.seconds();

        if (seconds < 0) {
            if (minutes < 0) {
                minutes = 15 - (Math.abs(minutes) % 15);
            } else if (minutes == 0) {
                minutes = 14;
            }

            seconds = 60 + seconds;
        }

        if (seconds > 30) {
            minutes++;
        }

        nextRebaseString = <h2>{minutes} <small>minute{minutes > 1 ? 's' : ''}</small></h2>;
    }


    useEffect(() => {
      dispatch(getBalances());
    }, [dispatch]);

    useInterval(() => {
        dispatch(getBalances());
    }, 5000);

    return (
        <>
            <div className={styles.rebaeHeadingPanel}>
                <Row className="mt-lg-4">
                    <Col xs="12" lg="6" className="d-flex align-items-center justify-content-center mb-4 mb-lg-0">
                        <div className="d-flex flex-column justify-content-center">
                            <h3>Your Rebae Balance</h3>
                            <h2>{toFormattedNumber(rebaeBalance, 2)}</h2>
                        </div>
                    </Col>
                    <Col xs="12" lg="6" className="d-flex align-items-center justify-content-center">
                        <div className="d-flex flex-column justify-content-center">
                            <h3>Next Rebase</h3>
                            {nextRebaseString}
                        </div>
                    </Col>
                </Row>
            </div>
            <div className={styles.rebaseDashPanel}>
                <Row>
                    <Col xs="12" lg="6" className="d-flex align-items-center justify-content-center mb-4 mb-lg-0">
                        <div className="d-flex flex-column justify-content-center">
                            <h3>Total Supply</h3>
                            <h2>{toFormattedNumber(totalSupply, 2)}</h2>
                            <h4>Rebae</h4>
                        </div>
                    </Col>
                    <Col xs="12" lg="6" className="d-flex align-items-center justify-content-center">
                        <div className="d-flex flex-column justify-content-center">
                            <h3>Circulating Supply</h3>
                            <h2>{toFormattedNumber(circulatingSupply, 2)}</h2>
                            <h4>Rebae</h4>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className={styles.rebaseDashPanel}>
                <Row className="pb-1">
                    <Col xs="12" lg="6" className="d-flex align-items-center justify-content-center mb-4 mb-lg-0">
                        <div className="d-flex flex-column justify-content-center">
                            <h3>Buy Tax</h3>
                            <h2>12<small>%</small></h2>
                        </div>
                    </Col>
                    <Col xs="12" lg="6" className="d-flex align-items-center justify-content-center">
                        <div className="d-flex flex-column justify-content-center">
                            <h3>Sell Tax</h3>
                            <h2>16<small>%</small></h2>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className={styles.rebaseDashPanel}>
                <Row>
                    <Col xs="12" lg="6" className="d-flex align-items-center justify-content-center mb-4 mb-lg-0">
                        <div className="d-flex flex-column justify-content-center">
                            <h3>Wallet Limit</h3>
                            <h2>{toFormattedNumber(maxWallet, 0)}</h2>
                            <h4>Rebae</h4>
                        </div>
                    </Col>
                    <Col xs="12" lg="6" className="d-flex align-items-center justify-content-center">
                        <div className="d-flex flex-column justify-content-center">
                            <h3>Daily Sell Limit</h3>
                            <h2>{toFormattedNumber(maxSell, 0)}</h2>
                            <h4>Rebae</h4>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default Dashboard;
