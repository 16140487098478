import { configureStore } from '@reduxjs/toolkit'
//import tokensReducer from './slices/tokensSlice'
import web3Reducer from './slices/web3Slice'

export default configureStore({
    reducer: {
        //tokens: tokensReducer,
        web3: web3Reducer,
    }
});
