import { createSlice } from '@reduxjs/toolkit'
import { Contract, ethers } from "ethers";
import { formatUnits } from 'ethers/lib/utils';
import moment from 'moment';
//import { loadHiddenTokens, queryBalances } from './tokensSlice';

export const web3Slice = createSlice({
  name: 'web3',
  initialState: {
    isConnected: false,
    isError: false,
    walletAddress: '',
    rebaeBalance: 0,
    lastBlockTimestamp: 0,
    lastRebaseTime: 0,
    totalSupply: 0,
    circulatingSupply: 0,
    maxWallet: 0,
    maxSell: 0
  },
  reducers: {
    connected: (state, action) => {
      state.isConnected = true;
      state.walletAddress = action.payload;
    },
    errorOnConnection: state => {
      state.isConnected = false;
      state.isError = true;
    },
    updateRebaeBalance: (state, action) => {
      state.rebaeBalance = action.payload;
    },
    updateLastBlockTimestamp: (state, action) => {
      state.lastBlockTimestamp = action.payload;
    },
    updateLastRebaseTime: (state, action) => {
      state.lastRebaseTime = action.payload;
    },
    updateTotalSupply: (state, action) => {
      state.totalSupply = action.payload;
    },
    updateCirculatingSupply: (state, action) => {
      state.circulatingSupply = action.payload;
    },
    updateMaxWallet: (state, action) => {
      state.maxWallet = action.payload;
    },
    updateMaxSell: (state, action) => {
      state.maxSell = action.payload;
    }
  }
})

export const connectWallet = () => {
    return async (dispatch, getState) => {
        try {
            await window.ethereum.enable();
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const walletAddress = await signer.getAddress();
            dispatch(connected(walletAddress));
            //dispatch(queryBalances());
        } catch (err) {
            dispatch(errorOnConnection());
        }
    }
}

export const getConnectedWallet = () => {
    return async (dispatch, getState) => {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const walletAddress = await signer.getAddress();
            dispatch(connected(walletAddress));
            dispatch(getBalances());
        } catch (err) {
        }
    }
}

export const getBalances = () => {
    return async (dispatch, getState) => {
        try {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const walletAddress = await signer.getAddress();

          const rebaeAbi = [
            'function balanceOf(address) view returns (uint)',
            'function _lastRebasedTime() view returns (uint)',
            'function _totalSupply() view returns (uint)',
            'function getCirculatingSupply() view returns (uint)',
            'function getUserHoldLimit() view returns (uint)',
            'function getUserSellLimit() view returns (uint)',
          ];
          const rebaeAddress = '0xa3f0efED39C21B5d7EbaC10d794533B6Bf361608';
          let rebaeContract = new Contract(rebaeAddress, rebaeAbi, provider);

          let rebaeBalance = await rebaeContract.balanceOf(walletAddress);
          dispatch(updateRebaeBalance(formatUnits(rebaeBalance, 5)));

          let lastBlockNumber = await provider.getBlockNumber();
          let lastBlockTimestamp = (await provider.getBlock(lastBlockNumber)).timestamp;
          dispatch(updateLastBlockTimestamp(formatUnits(lastBlockTimestamp, 0)));

          let lastRebaseTime = await rebaeContract._lastRebasedTime();
          dispatch(updateLastRebaseTime(formatUnits(lastRebaseTime, 0)));

          let totalSupply = await rebaeContract._totalSupply();
          dispatch(updateTotalSupply(formatUnits(totalSupply, 5)));

          let circulatingSupply = await rebaeContract.getCirculatingSupply();
          dispatch(updateCirculatingSupply(formatUnits(circulatingSupply, 5)));

          let maxWallet = await rebaeContract.getUserHoldLimit();
          dispatch(updateMaxWallet(formatUnits(maxWallet, 5)));

          let maxSell = await rebaeContract.getUserSellLimit();
          dispatch(updateMaxSell(formatUnits(maxSell, 5)));
        } catch (err) {
          console.error(err);
        }
    }
}


export const { connected, errorOnConnection, updateRebaeBalance, updateLastBlockTimestamp, updateLastRebaseTime, updateTotalSupply, updateCirculatingSupply, updateMaxWallet, updateMaxSell } = web3Slice.actions

export const selectIsConnected = state => state.web3.isConnected
export const selectIsError = state => state.web3.isError
export const selectWalletAddress = state => state.web3.walletAddress
export const selectRebaeBalance = state => state.web3.rebaeBalance
export const selectLastBlockTimestamp = state => state.web3.lastBlockTimestamp
export const selectLastRebaseTime = state => state.web3.lastRebaseTime
export const selectTotalSupply = state => state.web3.totalSupply
export const selectCirculatingSupply = state => state.web3.circulatingSupply
export const selectMaxWallet = state => state.web3.maxWallet
export const selectMaxSell = state => state.web3.maxSell

export default web3Slice.reducer